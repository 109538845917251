import { useEffect } from 'react'
import { navigate } from 'gatsby'

// import { Container } from 'semantic-ui-react'
// import Layout from '../components/Layouts/cakeDefault'

export default function GioiThieu() {
  // return (
  //   <Layout endPoint={'gioi-thieu'} title={'Savor Cake | Giới thiệu'}>
  //     <Container text style={{ marginTop: 20 }}>
  //       <h1>Giới thiệu về Savor Cake</h1>

  // <p>
  //   Savor Cake là thương hiệu bánh sinh nhật và bánh kem với nhiều mẫu mã đa
  //   đạng, giá cả phải chăng, nguồn nguyên liệu tươi ngon, đảm bảo VSATTP.{' '}
  // </p>

  //       <p>
  //         Với môi trường làm việc trẻ trung, năng động Savor đã, đang và sẽ
  //         không ngừng cung cấp những sản phẩm chất lượng cao đi kèm với dịch vụ
  //         hoàn hảo để mang lại sự trải nghiệm tốt nhất cho khách hàng.{' '}
  //       </p>

  //       <p>
  //         Với nguồn nhân lực trẻ và đầy nhiệt huyết, chúng tôi tin tưởng sẽ mang
  //         tới một môi trường làm việc thoải mái, được lắng nghe và tôn trọng ý
  //         kiến cá nhân, giờ trực linh hoạt không gò bó là cơ hội tốt để bạn cống
  //         hiến và phát huy giá trị cốt lõi của bản thân.
  //       </p>

  //       <p>
  //         Savor luôn chào đón những bạn trẻ với nhiệt huyết và khát khao tạo nên
  //         giá trị đích thực cho chính bản thân và cho khách hàng. Vì không ai
  //         trong chúng ta làm tốt bằng tất cả chúng ta cùng hợp sức!
  //       </p>
  //     </Container>
  //   </Layout>
  // )
  useEffect(() => {
    navigate('/')
  }, [])

  return null
}
